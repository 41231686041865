import React, { useEffect, useState } from "react";
import NavButton from "./Components/NavButton/NavButton";
import "./Header.scss";
import { IoIosClose, IoIosMenu } from "react-icons/io";
import { useHistory, useLocation } from "react-router";

function Header() {
    const [open, setOpen] = useState(false);
    const location = useLocation();
    const history = useHistory();

    useEffect(() => {
        setOpen(false);
    }, [location]);

    const scrollToConnect = () => {
        const connectDiv = document.getElementById("connect") as HTMLDivElement;
        connectDiv.scrollIntoView({ behavior: "smooth" });
        setOpen(false);
    };

    return (
        <>
            <header>
                <div className="Inner">
                    <div className="Buttons">
                        <span>
                            <img
                                src="/res/logo.svg"
                                alt="logo"
                                onClick={() => history.push("/")}
                            />
                            <NavButton text="Vending" url="/" />
                            <NavButton text="eMobility" url="https://www.voltality.io"/>
                            <NavButton text="Developers" url="https://beep-technologies.github.io/api-docs/" />
                        </span>
                        <span>
                            <NavButton
                                text="Contact Us"
                                action={scrollToConnect}
                            />
                            <NavButton text="Login" url="https://dashboard.beepbeep.tech/login" />
                        </span>
                        {open ? (
                            <IoIosClose
                                className="MenuButton"
                                size={24}
                                onClick={() => setOpen((o) => !o)}
                            />
                        ) : (
                            <IoIosMenu
                                className="MenuButton"
                                size={24}
                                onClick={() => setOpen((o) => !o)}
                            />
                        )}
                    </div>
                </div>
            </header>
            <div
                className="MenuBackground"
                style={{
                    opacity: open ? 0.6 : 0,
                    visibility: open ? "visible" : "hidden",
                }}
            />
            <div className="MenuContainer" style={{ maxWidth: open ? 200 : 0 }}>
                <NavButton text="Vending" url="/" />
                <NavButton text="eMobility" url="https://www.voltality.io"/>
                <NavButton text="Developers" url="https://beep-technologies.github.io/api-docs/" />
                <NavButton text="Contact Us" action={scrollToConnect} />
                <NavButton text="Login" url="https://dashboard.beepbeep.tech/login" />
            </div>
        </>
    );
}

export default Header;
