import React, { ReactNode, useMemo } from "react";
import { useState } from "react";
import { useHistory, useLocation } from "react-router";
import "./NavButton.scss";
import { IoChevronDown, IoChevronUp } from "react-icons/io5";

type P = {
    text: string;
    url?: string;
    action?: Function;
    children?: ReactNode;
};

function NavButton({ text, url, action, children }: P) {
    const history = useHistory();
    const location = useLocation();
    const selected = useMemo(() => {
        if (!url) return false;
        if (!children) {
            return location.pathname === url;
        }
        return location.pathname.startsWith(url);
    }, [url, location, children]);

    const [open, setOpen] = useState(false);

    const onClick = (e: React.MouseEvent) => {
        if(url?.startsWith('http')){
            window.location.href = url;
        } else if (!children || window.innerWidth > 768) {
            if (url) history.push(url);
            if (action) action();
            e.stopPropagation();
        } else {
            setOpen((o) => !o);
        }
    };

    return (
        <>
            <span
                className={`NavButton ${selected ? "Selected" : ""}`}
                tabIndex={0}
                onClick={onClick}
            >
                <small>{text}</small>
                {children &&
                    (open ? (
                        <IoChevronUp
                            className="Chevron"
                            size={12}
                            style={{ transform: "translate(4px, 2px)" }}
                        />
                    ) : (
                        <IoChevronDown
                            className="Chevron"
                            size={12}
                            style={{ transform: "translate(4px, 2px)" }}
                        />
                    ))}
                <div className="Children">{children}</div>
            </span>
            {open && <div className="MobileChildren">{children}</div>}
        </>
    );
}

export default NavButton;
