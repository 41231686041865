import React from "react";
import "./App.scss";
import Footer from "./Sections/Footer/Footer";
import Header from "./Sections/Header/Header";
import Payment from "./Sections/Payment/Payment";
import Connect from "./Sections/Connect/Connect";
import {
    BrowserRouter as Router,
    Switch,
    Route,
    useLocation,
} from "react-router-dom";
import SegmentsPage from "./Pages/SegmentsPage/SegmentsPage";
// import EVPage from "./Pages/EVPage/EVPage";
// import CMMSPage from "Pages/CMMSPage/CMMSPage";
import AOS from "aos";
import "aos/dist/aos.css";
import { useEffect } from "react";
AOS.init({
    once: true,
    delay: 160,
});

function ScrollToTop() {
    const { pathname } = useLocation();

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [pathname]);

    return null;
}

function App() {
    return (
        <div className="App">
            <Router basename="/">
                <ScrollToTop />
                <Header />
                <Switch>
                    {/* <Route exact path="/ev" component={EVPage} />
                    <Route exact path="/ev/cmms" component={CMMSPage} /> */}
                    <Route path="/" component={SegmentsPage} />
                </Switch>
                <Payment />
                <Connect />
                <Footer />
            </Router>
        </div>
    );
}

export default App;
