import React, { ReactNode } from "react";
import "./Stakeholder.scss";

type P = {
    title: string;
    body: ReactNode;
    imageUrl: string;
    quote?: {
        text: string;
        name: string;
        position: string;
        company: string;
    };
    rightSide?: boolean;
    imageTransform?: string;
    containerStyle?: React.CSSProperties;
    paneStyle?: React.CSSProperties;
    imageStyle?: React.CSSProperties;
    className?: string;
};

function Stakeholder({
    title,
    body,
    imageUrl,
    quote,
    rightSide = false,
    imageTransform = "",
    containerStyle = {},
    paneStyle = {},
    imageStyle = {},
    className,
}: P) {
    const imgStyle = { transform: imageTransform, ...imageStyle };
    return (
        <div
            className={`Stakeholder ${rightSide ? "Right" : ""} ${className}`}
            style={containerStyle}
        >
            <img
                className="LeftImage"
                src={imageUrl}
                alt=""
                // style={imgStyle}
                data-aos="fade"
                data-aos-duration={1500}
            />
            <div className={"Pane"} style={paneStyle} data-aos="fade-up">
                <h3>{title}</h3>
                {body}
                {quote && <span className="Quote">
                    <p>❝{quote.text}❞</p>
                    <small>
                        <b>{quote.name}</b>, {quote.position}
                    </small>
                    <br />
                    <small>{quote.company}</small>
                </span>}
            </div>
            <img
                className="RightImage"
                src={imageUrl}
                alt=""
                style={imgStyle}
                data-aos="fade"
                data-aos-duration={1500}
            />
        </div>
    );
}

export default Stakeholder;
