import React, { useState } from "react";
import "./Connect.scss";
import axios from 'axios';
import { vRequired, vEmail } from '../../helpers/validators'

type ContactForm = {
    name: string,
    email: string,
    contact_type: string,
    message: string
};

function Connect() {
    const [name, setName] = useState<string>('');
    const [email, setEmail] = useState<string>('');
    const [contactType, setContactType] = useState<string>('');
    const [message, setMessage] = useState<string>('');
    const [hasError, setHasError] = useState<boolean>(false);
    const [onSuccess, setOnSuccess] = useState<boolean>(false);

    const handleNameChange = (e:React.FormEvent<HTMLInputElement>) => {
        const value = e.currentTarget.value
        setName(value)
    };
    const handleEmailChange = (e:React.FormEvent<HTMLInputElement>) => {
        const value = e.currentTarget.value
        setEmail(value)
    };
    const handleContactTypeChange = (e:React.FormEvent<HTMLInputElement>) => {
        const value = e.currentTarget.value
        setContactType(value)
    };
    const handleMessageChange = (e:React.FormEvent<HTMLTextAreaElement>) => {
        const value = e.currentTarget.value
        setMessage(value)
    };

    const resetFields = () => {
        setName('')
        setEmail('')
        setContactType('')
        setMessage('')
    }

    const validateFields = () => {
        const validationHistory = [
            vRequired(name),
            vRequired(email),
            vEmail(email),
            vRequired(contactType),
            vRequired(message),
        ]

        const isAllValid = validationHistory.every(boolean => boolean === true)
        setHasError(!isAllValid)

        return isAllValid
    }

    const submitForm = async () => {
        const isValid = validateFields()

        if (isValid) {
            try {
                const body:ContactForm = {
                    name,
                    email,
                    contact_type: contactType,
                    message,
                }
    
                await axios.post('https://jy7fwwvcdd.execute-api.ap-southeast-1.amazonaws.com/default/landingContactFormEmail', body)
                setHasError(false)
                setOnSuccess(true)
                resetFields()
            } catch (error) {
                console.error(error)
            }
        }
        
    }

    return (
        <div
            className="Connect"
            style={{ backgroundImage: `url("/res/connect.svg")` }}
            id="connect"
        >
            <div className="ContentContainer" data-aos="fade">
                <h2>Let's Connect</h2>
                <p>
                    Send us a message to explore business solutions, partnership
                    opportunities or to make any enquiries.
                </p>
                <div className="Inputs">
                    <span>
                        <input type="text" name="name" placeholder="Name" value={ name } onChange={ handleNameChange } />
                        <input type="text" name="email" placeholder="Email" value={ email } onChange={ handleEmailChange } />
                    </span>
                    <input
                        type="text"
                        name="userType"
                        placeholder="I am an..."
                        value={ contactType } 
                        onChange={ handleContactTypeChange }
                    />
                    <textarea
                        name="message"
                        placeholder="How can I help you?"
                        value={ message }
                        onChange={ handleMessageChange }
                    />
                </div>
                {
                    hasError &&
                    <span className="ErrorText">Please fill in all the fields correctly</span>
                }
                {
                    onSuccess &&
                    <span className="PositiveText">Thank you for your submission, we will contact you shortly</span>
                }
                <button type="submit" className="SubmitButton" value="submit" onClick={ submitForm }>
                    Get Started
                </button>
            </div>
        </div>
    );
};

export default Connect;
