import React from "react";
import "./Payment.scss";

function Payment() {
    return (
        <div className="Payment">
            <h2 data-aos="fade">Payment Methods We Support</h2>
            <div className="IconContainer" data-aos="fade">
                {Array.from(Array(21).keys()).map((_, i) => (
                    <img
                        src={`/res/payment_icons/icon_${i + 1}.png`}
                        alt={`icon_${i + 1}`}
                        key={`icon_${i + 1}`}
                        loading="lazy"
                    />
                ))}
            </div>
        </div>
    );
}

export default Payment;
