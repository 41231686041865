import React from "react";
import "./Footer.scss";
import { AiOutlineLinkedin } from "react-icons/ai";

function Footer() {
    return (
        <footer>
            <div className="Inner">
                <img src="/res/logo.svg" alt="logo" />
                <span onClick={() => window.open("mailto:hello@beepbeep.tech")}>
                    <small>
                        <small className="Label">E</small>
                        hello@beepbeep.tech
                    </small>
                </span>
                {/* <span onClick={() => window.open("https://maps.google.com/")}>
                    <small>
                        <small className="Label">A</small>
                        SUTD Entrepreneurship Centre, 14 Somapah Rd, Singapore
                        409051
                    </small>
                </span> */}
                <span onClick={() => window.open("https://www.linkedin.com/company/beep-technologies-pte-ltd/")}>
                    <AiOutlineLinkedin />
                    <small>beep@linkedin</small>
                </span>
            </div>
        </footer>
    );
}

export default Footer;
