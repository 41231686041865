import React from "react";
import Section from "Sections/Section/Section";
import Stakeholder from "./Components/Stakeholder";
import "./SegmentsPage.scss";

function SegmentsPage() {
    return (
        <div className="SegmentsPage">
            <Section
                className="Banner"
                Title={() => (
                    <div>
                        <h1>Empowering <b>smart solutions.</b></h1>
                        <h3>Looking for COVID-19 ART Kit Diagnostics?</h3>
                    </div>
                )}
                button="Learn More"
                buttonAction={() => {
                    /*const connectDiv = document.getElementById(
                        "connect"
                    ) as HTMLDivElement;
                    connectDiv.scrollIntoView({ behavior: "smooth" });*/
                    window.location.href = "https://covid.beepbeep.tech/";
                }}
                videoUrl="/res/seg/banner.mp4"
            />
            <Section
                title="We can drive business growth the smart way"
                backgroundUrl="/res/seg/stakeholder_top_background.svg"
                style={{
                    backgroundSize: "40vw",
                    backgroundRepeat: "no-repeat",
                    backgroundPositionX: 0,
                    backgroundPositionY: 0,
                    backgroundColor: "white",
                }}
            >
                <div className="Stakeholders">
                    <Stakeholder
                        title="Retailers / Enterprise"
                        body={
                            <p>
                                <b>
                                    Delight your customers with innovative ways
                                    to interact with your brand
                                </b>{" "}
                                by tapping into our suite of APIs, allowing you
                                to seamlessly connect your existing channels
                                with any unattended touchpoints -{" "}
                                <b>no hardware knowledge required.</b>
                            </p>
                        }
                        imageUrl="/res/seg/stakeholder_1.svg"
                        // quote={{
                        //     text: "Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat supereme volutpat.",
                        //     name: "Andy",
                        //     position: "Assistant Manager",
                        //     company: "Dunder Miflin",
                        // }}
                    />
                    <Stakeholder
                        title="Operators"
                        body={
                            <p>
                                <b>
                                    Help your fleet realise its true potential
                                    through our unified platform
                                </b>{" "}
                                that works on any system in the market.{" "}
                                <b>Get real-time telemetric insights</b> for
                                increased operational efficiency whilst
                                supporting a broad range of local and global
                                payments, or even run promotions and loyalty
                                programmes{" "}
                                <b>to constantly engage your customers.</b>
                            </p>
                        }
                        imageUrl="/res/seg/stakeholder_2.svg"
                        // quote={{
                        //     text: "Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat supereme volutpat.",
                        //     name: "Andy",
                        //     position: "Assistant Manager",
                        //     company: "Dunder Miflin",
                        // }}
                        rightSide
                        className="Stakeholder-Operations"
                    />
                    <Stakeholder
                        title="Distributors"
                        body={
                            <p>
                                <b>Add value for your clients' businesses</b> by
                                being a one-stop shop for unattended systems and
                                peripherals by packaging smart, affordable
                                capabilities into their machines.
                            </p>
                        }
                        imageUrl="/res/seg/stakeholder_3.svg"
                        // quote={{
                        //     text: "Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat supereme volutpat.",
                        //     name: "Andy",
                        //     position: "Assistant Manager",
                        //     company: "Dunder Miflin",
                        // }}
                    />
                    <Stakeholder
                        title="Manufacturers"
                        body={
                            <p>
                                <b>Gain competitive advantage</b> by enabling
                                your machines to support payments and using our
                                out-of-the-box innovation globally – no added
                                hardware required.
                                <b>
                                    Give your customers unparalleled flexibility
                                </b>{" "}
                                to create new user experiences through online,
                                remote and automatic payment capabilities that{" "}
                                <b>
                                    push the boundaries of what machines can do.
                                </b>
                            </p>
                        }
                        imageUrl="/res/seg/stakeholder_4.svg"
                        // quote={{
                        //     text: "Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat supereme volutpat.",
                        //     name: "Andy",
                        //     position: "Assistant Manager",
                        //     company: "Dunder Miflin",
                        // }}
                        rightSide
                        className="Stakeholder-Manufacturers"
                    />
                </div>
            </Section>
        </div>
    );
}

export default SegmentsPage;
