const validateWithWarning = (validator: () => boolean, message: string) => {
  const isValid = validator()

  if (!isValid) { console.warn(message) }

  return isValid
}

const required = (value:any) => {
  return (
    value !== undefined && 
    value !== null && 
    value !== '')
}

export const vRequired = (value:any) => validateWithWarning(
  () => required(value),
  `[vRequired]: Required field received "${value}"`
)

const emailRegex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

export const vEmail = (value:string) => validateWithWarning(
  () => emailRegex.test(value),
  `[vEmail]: "${value}" is not a valid email`
)

/* eslint import/no-anonymous-default-export: [2, {"allowObject": true}] */
export default {
  vRequired,
  vEmail
}