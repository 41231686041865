import React, { ReactNode } from "react";
import { useHistory } from "react-router";
import "./Section.scss";

type P = {
    topTitle?: string;
    title?: string;
    Title?: React.ComponentType;
    subtitle?: string;
    button?: string;
    buttonUrl?: string;
    buttonAction?: Function;
    backgroundUrl?: string;
    videoUrl?: string;
    backgroundColor?: string;
    className?: string;
    children?: ReactNode;
    style?: React.CSSProperties;
};

function Section({
    topTitle,
    title,
    Title,
    subtitle,
    button,
    buttonUrl,
    buttonAction,
    backgroundUrl,
    videoUrl,
    className = "",
    style: paneStyle = {},
    children,
}: P) {
    const history = useHistory();
    const buttonPressed = () => {
        if (buttonUrl) history.push(buttonUrl);
        if (buttonAction) buttonAction();
    };
    return (
        <div
            className={`Section ${className} ${
                children ? "WithChildren" : ""
            } ${videoUrl ? "Shadow" : ""}`}
            style={{
                backgroundImage: `url(${backgroundUrl})`,
                ...paneStyle,
            }}
        >
            {videoUrl && (
                <video id="background-video" muted loop autoPlay>
                    <source src={videoUrl} type="video/mp4" />
                    Your browser does not support the video tag.
                </video>
            )}
            <div className="LeftPane">
                {!!topTitle && (
                    <h4 data-aos="fade" className="TopTitle">
                        {topTitle}
                    </h4>
                )}

                {!!title && <h2 data-aos="fade-left">{title}</h2>}
                {!!Title && <Title data-aos="fade-left" />}
                <p data-aos="fade">{subtitle}</p>
                {!!button && (
                    <button data-aos="fade" onClick={buttonPressed}>
                        {button}
                    </button>
                )}
            </div>
            {!!children && children}
        </div>
    );
}

export default Section;
